<template>
    <!--login外框-->
    <div class="_login" v-loading="loading"
         element-loading-text="加载中,请稍后"
         element-loading-spinner="el-icon-loading"
         element-loading-background="rgba(0, 0, 0, 0.8)">
        <!--<div class="logo"></div>-->
        <!--&lt;!&ndash;login内框&ndash;&gt;-->
        <!--<div class="login_inner">-->
            <!--<el-form class="form" ref="form" :model="formLogin">-->
                <!--<el-form-item>-->
                    <!--<h2 class="title">城市配管理系统</h2>-->
                <!--</el-form-item>-->
                <!--<el-form-item>-->
                    <!--<el-input v-model="formLogin.username" placeholder="账号"></el-input>-->
                <!--</el-form-item>-->
                <!--<el-form-item>-->
                    <!--<el-input v-model="formLogin.password" placeholder="密码" show-password></el-input>-->
                <!--</el-form-item>-->
                <!--<el-form-item>-->
                    <!--<el-button type="primary" @click="login">登陆</el-button>-->
                    <!--<div v-show="this.errorInfo.isShowError" class="error">{{this.errorInfo.text}}</div>-->
                <!--</el-form-item>-->
                <!--<el-form-item>-->
                    <!--<el-link type="primary" underline @click="reg">点此注册>>>>>></el-link>-->
                    <!--<el-link type="primary" underline @click="forgetPassword" style="float: right">忘记密码</el-link>-->
                <!--</el-form-item>-->
            <!--</el-form>-->
        <!--</div>-->
        <!--<div class="foter_title">浙公网安备33010802003130号 © <a href="https://beian.miit.gov.cn/" style="color: #ffffff;text-decoration:underline;" target="_blank">浙ICP备15040565号-1</a> © 版权所有 浙江泰易达物流科技有限公司</div>-->
    </div>
</template>
<style lang="scss">
    ._login {
        // border:1px solid red;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url("../assets/login.jpg");
        background-size: cover;
        .logo{
            width: 60px;
            height: 20px;
            position: absolute;
            left: 50px;
            top: 30px;
            background-image: url("../assets/logo.png");
            background-size: cover;
        }
        .login_inner {
            // border:1px solid green;
            width: 460px;
            height: 400px;
            background: #ffffff;
            position: absolute;
            top:150px;
            right: 20px;
            border-radius:5px;
            display: flex;
            justify-content: center;
            box-shadow: 0 0 25px #cac6c6;
            .form {
                // border:1px solid blue;
                width: 300px;
                margin-top: 30px;
                text-align: center;
                .title {
                    color: #505458;
                }
            }
            .error {
                color: red;
            }
        }
        .foter_title{
            width: 100%;
            height: 30px;
            position: absolute;
            left: 0;
            bottom: 5px;
            text-align: center;
            color: #ffffff;
        }
    }
</style>
<script>
    import { mapActions } from 'vuex'
    import axios from 'axios'
    import qs from 'qs'
    import {Encrypt} from '../util/secret.js'
    export default {
        name: "login",
        data() {
            return {
                loading:true,
                id:'',
                formLogin: {
                    username: "admin01",
                    password: "p19283746//",
                },
                errorInfo: {
                    text: "登陆失败,请重试",
                    isShowError: false //显示错误提示
                },
                menuTreeList:[]
            };
        },
        created () {

        },
        mounted() {
            this.login();
            document.onkeydown = (event) => {
                var router=this.$route.path;
                var e = event || window.event || arguments.callee.caller.arguments[0];
                if (e && e.keyCode == 13&&this.$route.path=='/login') { // enter 键
                    this.login();
                }
            };
        },
        methods: {
            ...mapActions({
                setDeveloperInfo: 'developer/setDeveloperInfo',
            }),
            //查询用户所能看到菜单树
            getMenuTree(id){
                axios.get(`${this.$apiPath}/api/pm/login/queryMenus/`+id).then(res=>{
                    this.menuTreeList= res.data;
                    let menu = res.data;
                    //存全部的菜单
                    sessionStorage.setItem('menu', JSON.stringify(menu))
                    this.setDeveloperInfo({
                        data: {
                            authorManagement: false,
                            cityDistribution: false,
                            controlTime: false,
                        }
                    })
                })
                axios.get(`${this.$apiPath}/api/pm/login/queryButtons/`+id).then(res=>{
                    sessionStorage.setItem('userButtons', JSON.stringify(res.data))
                })
            },
            login() {
                if(this.formLogin.username == ''){
                    this.$notify.error({
                        title: '通知',
                        message: '请输入您的用户名'
                    });
                }else if(this.formLogin.password == ''){
                    this.$notify.error({
                        title: '通知',
                        message: '请输入您的密码'
                    });
                }else{
                    //提交登录
                    let param = {
                        username:this.formLogin.username,
                        password:Encrypt(this.formLogin.password),
                    };
                    axios.post(`${this.$apiPath}/api/auth/loginCity`,qs.stringify(param))
                        .then((response) => {
                            if(response.data.success == true){
                                this.ceshi();
                                setTimeout(() => {
                                    this.$router.replace({ path: "/index" });
                                    this.loading = false;
                                }, 1000)
                            }else {
                                this.$alert(response.data.msg, '提示', {
                                    confirmButtonText: '确定',
                                });
                            }
                        }).catch((err)=>{
                    })
                }
            },
            reg(){
                let routeUrl = this.$router.resolve({
                    path: "/reg"
                });
                window.open(routeUrl.href, '_blank');
            },
            forgetPassword(){
                let routeUrl = this.$router.resolve({
                    path: "/forgetPassword"
                });
                window.open(routeUrl.href, '_blank');
                //this.$router.replace({ path: "/forgetPassword" });
            },
            ceshi(){
                axios.get(`${this.$apiPath}/api/auth/verify`).then(res=>{
                    this.getMenuTree(res.data.id);
                    let userPermissions = {};
                    userPermissions.id  = res.data.id;
                    userPermissions.chintBusiness = res.data.chintBusiness;
                    userPermissions.outGoing = res.data.outGoing;
                    userPermissions.regionLevel = res.data.regionLevel;
                    sessionStorage.setItem('userPermissions', JSON.stringify(userPermissions))
                })
            }
        }
    };
</script>
